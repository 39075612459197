<template>
  <div>
    <RevTag v-if="badge" :label="badge" variant="info" />
    <h1 :class="headingClasses">
      {{ title }}
    </h1>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { BannerTextColor } from '@backmarket/http-api/src/api-specs-content/models/banner'
import { tw } from '@backmarket/utils/string/tw'
import { RevTag } from '@ds/components/Tag'

import { getBannerTextColorClass } from '../../helpers/banner/getTextColor'

const props = withDefaults(
  defineProps<{
    title?: string
    badge?: string
    textColor?: BannerTextColor
    size?: 'medium' | 'large'
  }>(),
  {
    title: '',
    textColor: BannerTextColor.Black,
    badge: '',
    size: 'medium',
  },
)

const headingClasses = computed(() => {
  return [
    getBannerTextColorClass(props.textColor),
    props.size === 'medium' ? tw`heading-1` : tw`punchline`,
  ]
})
</script>
